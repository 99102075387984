@import "../../styles/_colors-variables.scss";

.customized-reviews-analysis {
  .top-title-bar {
    padding-bottom: 34px;
    flex-wrap: wrap;
    .left-title {
      font-weight: 700;
      font-size: 32px;
      line-height: 130%;
      color: #121863;
    }
    .rights {
      .btn {
        margin-left: 16px;
        .icons {
          width: 20px;
          margin-right: 10px;
          height: 18px;
        }
        .icon-add {
          background: url("/assets/blue-add.svg") center center no-repeat;
          background-size: 20px 24px;
        }
      }
    }
  }
}
