@import "../../../styles/_responsive-variables.scss";
@import "../../../styles/_colors-variables.scss";

div.hook-form-textarea {
  display: flex;
  flex-direction: column;
  gap: 4px;

  div.hook-form-textarea-container {
    display: flex;
    flex-direction: column;

    border: 1px solid $lightBlue;
    border-radius: 8px;

    overflow: hidden;

    &:focus-within {
      border-color: $themeBlue;
      outline: 1px solid $themeBlue;
    }

    textarea {
      padding: 16px;
      border: none;
      min-height: 120px;
      height: auto;

      font-size: 17px;
      color: $textColor;

      width: 100%;
      resize: none;
    }

    button {
      border-radius: 0;
    }
  }

  div.hook-form-status-display {
    display: flex;
    flex-direction: row;
    gap: 0.5em;
    align-items: start;

    span.hook-form-status-display-chars {
      font-size: 12px;
      color: $grayColor;
      &.over {
        color: $errorColor;
      }
    }

    div.hook-form-error {
      flex: 1;
    }
  }
}
