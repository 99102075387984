@import "../../../styles/_colors-variables.scss";

.modal-default.modal-new-analysis-confirm {
  background-color: rgba(0, 0, 0, 0.2);

  .modal-mains {
    margin: 50px auto 50px;
    width: 900px;
    height: auto;
    padding: 32px 40px;
    .btn-close {
      width: 40px;
      height: 40px;
      &:hover {
        opacity: 0.9;
      }
    }

    &__main {
      max-height: 90vh;
      overflow: scroll;
    }

    .content {
      color: "#121863";
    }

    .bottom-btns {
      margin-top: 0;
      .btn {
        min-width: 160px;
      }
    }
    .row-form {
      margin-bottom: 40px;
      &.row {
        margin: 0 -8px 40px;
      }
      .col {
        padding: 0 8px;
      }
      &.mb60 {
        margin-bottom: 30px;
      }
    }
  }
}
