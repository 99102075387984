@import "../../styles/_colors-variables.scss";

.function-management {
  // .top-title-bar
  .top-title-bar {
    padding-bottom: 0px;
    flex-wrap: wrap;

    .left-title {
      font-weight: 700;
      font-size: 32px;
      line-height: 130%;
      color: #121863;
    }

    .line {
      flex: 0 0 100%;
      height: 4px;
      margin-top: 30px;
      margin-bottom: 5px;
      background: #c9d2df;
    }

    .right-btns {
      display: flex;
      gap: 16px;
    }
  }

  .default-settings {
    font-weight: 700;
    font-size: 18px;
    color: #121863;
    margin-bottom: 20px;
  }

  .individual-settings {
    font-weight: 700;
    font-size: 18px;
    color: #121863;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .list-top-bar {
    padding-bottom: 10px;
  }
}
