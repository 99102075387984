@import "../../../styles/_colors-variables.scss";

div.time-of-day-input {
  // A few tweaks to make the react-datepicker look better / fit long captions
  div.react-datepicker__time-container {
    width: fit-content;
    div.react-datepicker__header {
      padding: 8px 12px;
    }
    div.react-datepicker__time-box {
      width: 100%;
    }
  }
  input {
    width: 80px;
    height: 43px;
    border: 1px solid $lightBlue;
    border-radius: 8px;
    padding: 0 16px;
    font-size: 17px;

    &:disabled {
      color: $grayColor;
    }

    &.open {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      text-align: right;
    }
    &.close {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}
