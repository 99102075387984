@import "../../../../styles/_colors-variables.scss";

button.accordion-button-label {
  border: none;
  background: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 16px 24px;
  font-size: 18px;
  font-weight: 700;
  background-color: $lightBlue;
  &.active {
    background-color: $themeBlue;
    color: $white;
  }
  &::after {
    content: "";
    display: inline-block;
    width: 25px;
    height: 25px;
    background: url(/assets/white-drop.svg) center center no-repeat;
    display: inline-block;
    background-size: 18px auto;
    stroke: $textColor;
  }
  &.active::after {
    transform: rotate(180deg);
  }
}
